import React from "react";
import './transaction.css';
import { fmtDateTimeFull } from "@src/extensions/utils";
import dayjs from "dayjs";

const Transaction = ({...props}) => {
    const { transaction } = props;

    return<div className="transaction-item">
        <div>{transaction.operationType == 0 ? 'Оплата' : 'Возврат' }</div>
        <div>{fmtDateTimeFull(dayjs(transaction.createdDateTime))}</div>
        <div>{transaction.fiscalReceiptUrl && <a href={transaction.fiscalReceiptUrl} target="_blank"><strong>Чек</strong></a>}</div>
        <div>{transaction.quantity} руб.</div>
    </div>
}

export default Transaction;