import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Carousel, Input, message, Tooltip, Button, Form, Checkbox, Spin, Skeleton, Radio, Space } from 'antd';
import { serverFetch } from '@src/server';
import { useElementSize } from 'usehooks-ts';
import { IOptionalService, IInfrastructure, IRoomCategory, IOptionalProffitService, IReservationRoomFilter, ICalcAvailResultRoom, IOrder, ICompany } from '@entities/index';
import { IOrderStatus, ICompanyTransKind, IOrderTransVid } from '@enums/index';
import { useWindowWidth } from '@react-hook/window-size';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useAppDispatch } from '@store/hooks';
import { MaskedInput } from 'antd-mask-input';
import { useSelector } from 'react-redux';
import { userLoaded } from '@src/store/actions';
import jwt_decode from 'jwt-decode';
import BookingOrderFloat from './booking-order-float';
import LocalFuncs from '@src/extensions/local-funcs';
import { strToInt } from '@src/extensions/utils';
import IBookingOrderData from './booking-order-data';
import DataOpers from '@src/extensions/data-opers';
import { MainHeader, MainFooter } from '../main/index';
import SearchRoomsFilter from '../search-categories/search-companies-filter';
import './booking-order.css';
import { Spinner } from '@src/controls';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/es/carousel';

// Окончательное подтверждение бронирования перед созданием заявки в базе
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const BookingOrder = () => {
    // Переданные параметры  
    const {companyId} = useParams();
    // Параметры URL (могут меняться)
    const urlParams = new URLSearchParams(window.location.search);
    const roomCategoryId = strToInt(urlParams.get('roomCategoryId'));
    const pricePeriodId = strToInt(urlParams.get('pricePeriodId'));
    const roomPlacingId = urlParams.get('roomPlacingId') ?? '';
    const promocode = urlParams.get('promocode');
    const tariffExtraCode = urlParams.get('tariffExtraCode');
    const orderTimeId = strToInt(urlParams.get('orderTimeId'));
    // Загруженные параметры при старте
    const [company, setCompany] = useState<ICompany>();
    const [vars, setVars] = useState<Array<ICalcAvailResultRoom>>([]);
    const [roomCategories, setRoomCategories] = useState<Array<IRoomCategory>>([]);
    // Параметры контекста
    const isMobile: boolean = useWindowWidth() <= 1130;
    const [form] = Form.useForm();
    const isModuleMode = window.self !== window.top;
    const user = useSelector((s:any) => s.user);
    const [filter, _] = useState(LocalFuncs.checkFilterPeriod(useSelector((s:any) => s.reservationRoomFilter)));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const ref0 = useRef<CarouselRef>(null);
    const ref = useRef<CarouselRef>(null);

    const [squareRef, { width, height }] = useElementSize();
    const [optionalServices, setOptionalServices] = useState<IOptionalService[]>();
    const [orderData, setOrderData] = useState<IBookingOrderData>({ email: '', code: '', codeSent: false, emailChecked: false, firstName: '', secondName: '', lastName: '', phone: '', originalCode: '', extraComment: '' });
    const [agreed, setAgreed] = useState<boolean>(false);
    const [agreed2, setAgreed2] = useState<boolean>(false);
    const [booking, setBooking] = useState<boolean>(false);
    const d = useAppDispatch();
    const [optionalProffitSerives, setOptionalProffitSerives] = useState<IOptionalProffitService[]>([]);
    const [selectedPit, setSelectedPit] = useState<number>(0);

    useEffect(() => DataOpers.getCompany(companyId, setCompany), [companyId]); // Получить компанию
    useEffect(() => DataOpers.getOptionalServices(pricePeriodId, setOptionalServices, 'Получение услуг'), []); // Получение типов питания для Проффита
    useEffect(() => DataOpers.getCategories(companyId, setRoomCategories, 'Получение категорий'), []);

    // console.log(orderTimeId);
    // console.log(selectedPit);
    // Получить варианты питания если это не Алеан
    useEffect(() => {
        company?.aleanSystem || serverFetch(`Booking/GetTariffServices/${pricePeriodId}/${roomCategoryId}`, { method: 'GET' })
           .then((data) => {
               setOptionalProffitSerives(data);
               if (!data.find((item2: IOptionalProffitService) => item2 && item2.id == selectedPit)) {
                   const item = data.find((item2: IOptionalProffitService) => item2 && item2.isDefault);
                   if (item) setSelectedPit(item.id);
               }
           })
           .catch(e => message.error(`Ошибка получения услуг ${e.userMessage || ''} `));
        
   }, [company,roomCategoryId]); // company,roomCategoryId

    // Получить варианты размещения в компании по фильтру по всем категориям с сортировкой по стоимость по возрастанию для определения следущей ступени улучшения 
    useEffect(() => {
        setLoading(true);
        const calcAsk = {
            adults: Number(filter.adultsQty),
            arrival: filter.arrivalDate.format('YYYY-MM-DD'),
            companyId,
            departure: filter.departureDate.format('YYYY-MM-DD'),
            tariffId: [],
            tarifs: [],
            userType: 0,
            childrenAges: filter.childAges,
            promocode: promocode,
            orderTimeId,
            vidPitId: selectedPit,
        };
        serverFetch(`calc/GetAvailableRooms`, { method: 'POST', bodyData: calcAsk, }) 
            .then((data:any) => {
                setVars(data.rooms.sort((a: ICalcAvailResultRoom, b: ICalcAvailResultRoom) => a.amountWithDiscount - b.amountWithDiscount));
                setLoading(false);
            })
            .catch((e) => {
                message.error(`Ошибка получения вариантов размещения ${e.userMessage || ''} `);
                setLoading(false);
            });
    }, [selectedPit]); // [companyId, filter]


    // При смене пользователя (авторизации) подставлять данные пользователя
    useEffect(() => {
        if (user) {
            const newOrderData = { ...orderData, email: user.email, emailChecked: true, firstName: user.touristName, secondName: user.touristSurname, lastName: user.touristPatronymic, phone: user.touristPhone };
            setOrderData(newOrderData)
            form.setFieldsValue(newOrderData);
        }
        else setOrderData({ ...orderData, email: '',code: '', codeSent: false, emailChecked: false, firstName: '', secondName: '', lastName: '', phone: '' });    
    }, [user]);
        
    const contentStyle: React.CSSProperties = { margin: 0, width: width, height: 230, color: '#fff', lineHeight: '160px', textAlign: 'center', background: '#364d79' };
    const onSendCode = async () => {
        const email = form.getFieldValue("email");
        serverFetch(`Auth/GenerateCheckcode`, {method: 'POST', bodyData: { email }})
            .then(() => setOrderData({...orderData, codeSent: true }))
            .catch(e => message.error(`Ошибка отправки кода ${e.userMessage || ''} `));
    };
    const onCheckCode = async () => {
        const email = form.getFieldValue("email");
        serverFetch(`Auth/signin`, {method: 'POST', bodyData: { email, checkcode: orderData.code } })
            .then((data) => {
                localStorage.setItem('refreshToken', data.refreshToken);
                var result:any = jwt_decode(data.accessToken);
                let session = {
                    userId: result.userId,
                    touristName: result.touristName,
                    touristSurname: result.touristSurname,
                    touristPatronymic: result.touristPatronymic,
                    email: result.email,
                    touristPhone: result.touristPhone,
                };
                d(userLoaded(session));
                const element = document.getElementById('booking-order-btn-book');
                if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            })
            .catch(() => message.error(`Неверный код`));
    };
    const onValuesChange = (a: any) => setOrderData({ ...orderData, ...a });
    // Текущий тариф 
    //console.log(roomPlacingId, vars);
    const currentVar = vars && vars.find(i => i.roomPricePeriodId == pricePeriodId && i.roomCategoryId == roomCategoryId 
        && (!roomPlacingId || roomPlacingId == i.aleanRoomPlacing.roomPlacingID));
    // Текущий вариант размещения - поиск по roomCategoryid
    const SlickArrowLeft0 = () => <Button aria-hidden='true' shape='circle' icon={<LeftOutlined />} className="slick-arrow slick-prev" onClick={() => ref0.current?.prev()}/>
    const SlickArrowRight0 = () => <Button aria-hidden='true' shape='circle' icon={<RightOutlined />} className="slick-arrow slick-next" onClick={() => ref0.current?.next()}/>

    const currentCat = currentVar && roomCategories.find(i => currentVar.roomCategoryExtraId ? i.roomCategoryExtraId == currentVar.roomCategoryExtraId : i.roomCategoryId == roomCategoryId);
    const divCurrent = currentCat ? <>
        <div className='reserve-column' style={{ width: 350 }}>
            <div className='reserve-section-title'>Категория размещения</div>
            <div style={{ width: 350 }} ref={squareRef} className='reserver-hotel-gallery'>
                {currentCat && currentCat.gallery && currentCat.gallery.length > 0 ? <Carousel arrows={true} prevArrow={<SlickArrowLeft0 />} nextArrow={<SlickArrowRight0 />} slidesToScroll={1} ref={ref0}>
                    {currentCat && currentCat.gallery?.map((i) => <div key={i.imageId}>
                        <img style={contentStyle} src={i.source ? i.source : `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`} />
                    </div>)}
                </Carousel> : <Skeleton.Image active />}
            </div>
            <div className='reserve-hotel-infra'>
                {currentCat &&
                    currentCat?.infrastructure &&
                    currentCat?.infrastructure.map((e: IInfrastructure) => <Tooltip title={e.name} key={e.infrastructureID}>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID} />
                    </Tooltip>)}
            </div>
        </div>
        <div className='reserve-column'>
            <div className='reserve-section-title'>{currentCat && currentCat.name}</div>
            <div className='reserve-hotel-description'>{currentCat && currentCat.description}</div>
        </div>
    </> : <div className='reserve-section-title'>Категория размещения не выбрана</div>    
    // Следующая категория размещения - поиск следующего варианта у которого при том же тарифе pricePeriodId больше стоимость
    const nextVar = currentVar && vars.find(i => i.roomPricePeriodId == currentVar?.roomPricePeriodId && i.amountWithDiscount > currentVar.amountWithDiscount);
    const nextCat = nextVar && roomCategories.find(i => nextVar.roomCategoryExtraId ? i.roomCategoryExtraId == nextVar.roomCategoryExtraId : i.roomCategoryId == nextVar.roomCategoryId);
    //console.log(currentVar, nextVar);
    //console.log(currentCat, nextCat);
    const goNext = () => {
        const newURL = new URLSearchParams();
        pricePeriodId && newURL.set('pricePeriodId', nextVar?.roomPricePeriodId + "");
        //tariffExtraCode && newURL.set('tariffExtraCode', tariffExtraCode);
        roomCategoryId && newURL.set('roomCategoryId', nextVar?.roomCategoryId + "");
        let url = `/booking/order/${companyId}?`;
        navigate(url+newURL.toString());
    }
    // Переход в поиск по компании
    const goCompany = () => {
        const url = `/booking/search/category/${companyId}`;
        window.location.href = url;
    }

    const SlickArrowLeft = () => <Button aria-hidden='true' shape='circle' icon={<LeftOutlined />} className="slick-arrow slick-prev" onClick={() => ref.current?.prev()}/>
    const SlickArrowRight = () => <Button aria-hidden='true' shape='circle' icon={<RightOutlined />} className="slick-arrow slick-next" onClick={() => ref.current?.next()}/>

    const divNext = nextCat && <>
        <div className='reserve-column'>
            <div className='reserve-section-title'>Повысить комфортность размещения?</div>
            <div style={{ width: 350 }} ref={squareRef} className='reserver-hotel-gallery'>
                {nextCat && nextCat.gallery && nextCat.gallery.length > 0 ? <Carousel arrows={true} prevArrow={<SlickArrowLeft />} nextArrow={<SlickArrowRight />} ref={ref}>
                        {nextCat && nextCat.gallery?.map((i) => <div key={i.imageId}>
                        <img style={contentStyle} src={i.source ? i.source : `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`} />
                        </div>)}
                    </Carousel> : <Skeleton.Image active />}
            </div>
            <div className='reserve-hotel-infra'>
                {nextCat && nextCat?.infrastructure && nextCat?.infrastructure.map((e: IInfrastructure) => (
                        <Tooltip title={e.name} key={e.infrastructureID}>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID} />
                        </Tooltip>
                    ))}
            </div>
        </div>
        <div className='reserve-column'>
            <div className='reserve-hotel-name'>{nextCat && nextCat.name}</div>
            <div className='reserve-hotel-description'>{nextCat && nextCat.description}</div>
            {nextVar && <div className='reserver-tariff'>
                <div className='reserver-hotel-tariff-price'>
                    + {currentVar && (nextVar.amountWithDiscount - currentVar.amountWithDiscount).toLocaleString('ru-RU')} ₽
                </div>
            </div>}
            <Button onClick={goNext} className='action-button'>ПОВЫСИТЬ КАТЕГОРИЮ НОМЕРА</Button>
        </div>
    </>
    
    // Создание заявки
    const onBook = () => {
        setBooking(true);
        const selectedPitObj = optionalProffitSerives && optionalProffitSerives.find((item) => item.id == selectedPit);
        const vidPitName = selectedPitObj ? selectedPitObj.name : '';
        const roomPricePeriodId = currentVar ? currentVar.roomPricePeriodId : 0;
        let proffitRoomCategoryId = 0;
        if (company?.proffitSystem && currentCat) proffitRoomCategoryId = currentCat.proffitId;
        else if (company?.aleanSystem && currentCat) proffitRoomCategoryId = currentVar.roomCategoryId ?? 0;
        let arrivalTime = roomPricePeriodId ? dayjs(filter.arrivalDate) : null;
        let departureTime = roomPricePeriodId ? dayjs(filter.departureDate) : null;
        if (company?.aleanSystem && currentVar && currentVar.aleanVisit && currentVar.aleanVisit.begin) arrivalTime = currentVar.aleanVisit.begin;
        if (company?.aleanSystem && currentVar && currentVar.aleanVisit && currentVar.aleanVisit.end) departureTime = currentVar.aleanVisit.end;
        
        const order: IOrder = {
            orderId: 0,
            orderDateTime: dayjs(),
            companyId: Number(companyId),
            email: orderData.email,
            phone: orderData.phone,
            arrivalDate: filter.arrivalDate.format('YYYY-MM-DD'),
            departureDate: filter.departureDate.format('YYYY-MM-DD'),
            countAdults: Number(filter.adultsQty),
            countChilds: filter.childsQty,
            countChildsWithBeds: currentVar && currentVar.qtyChildrenWithBeds ? currentVar.qtyChildrenWithBeds : 0,
            price: currentVar ? currentVar.amountWithDiscount : 0,
            additionalComments: orderData.extraComment,
            arrivalTime,
            departureTime,
            childAge1: filter.childsQty >= 1 && filter.childAges.length >= 1 ? filter.childAges[0] : 0,
            childAge2: filter.childsQty >= 2 && filter.childAges.length >= 2 ? filter.childAges[1] : 0,
            childAge3: filter.childsQty >= 3 && filter.childAges.length >= 3 ? filter.childAges[2] : 0,
            childAge4: filter.childsQty >= 4 && filter.childAges.length >= 4 ? filter.childAges[3] : 0,
            childAge5: filter.childsQty >= 5 && filter.childAges.length >= 5 ? filter.childAges[4] : 0,
            childAge6: filter.childsQty >= 6 && filter.childAges.length >= 6 ? filter.childAges[5] : 0,
            childAge7: filter.childsQty >= 7 && filter.childAges.length >= 7 ? filter.childAges[6] : 0,
            childAge8: filter.childsQty >= 8 && filter.childAges.length >= 8 ? filter.childAges[7] : 0,
            childAge9: filter.childsQty >= 9 && filter.childAges.length >= 9 ? filter.childAges[8] : 0,
            childAge10: filter.childsQty >= 10 && filter.childAges.length >= 10 ? filter.childAges[9] : 0,
            roomPricePeriodId,
            isProffit: true,
            proffitRoomCategoryId,
            orderStatus: IOrderStatus.New,
            payment: 0,
            touristFirstName: orderData.firstName,
            touristSecondName: orderData.secondName,
            touristLastName: orderData.lastName,
            orderNumber: '',
            touristName: '',
            orderTimeId: Number(orderTimeId),
            vidPitId: selectedPit,
            vidPitName,
            transVid: orderData.transVid,
            transName: orderData.transName,

            aleanLine: currentVar?.aleanVisit?.line,
            aleanSessionId: currentVar?.aleanSessionId,
            aleanRoomPlacing: currentVar?.aleanRoomPlacing,
            aleanTouristTypes: currentVar?.aleanTouristTypes,
            roomCategoryExternalId: currentVar?.roomCategoryExtraId,
            roomCategoryName: currentVar?.roomCategoryName,
            tariffName: currentVar?.tariffName,
            roomCategoryId: currentVar?.roomCategoryId,  
        };
        // let data = order;
        serverFetch(`Booking/Book`, { method: 'POST', bodyData: order })
            .then((orderId: string) => {
                console.log(`Сформирован заказ ${orderId}`);
                const url = `${window.location.origin}/booking/${orderId}`;
                window.location.href = url;
            })
            .catch((e) => {
                setBooking(false);
                message.error(`Ошибка формирования заказа ${e.userMessage || ''} `);
            });
    };

    const needTrans = () => company && company.transKind == ICompanyTransKind.PublicAndCar && orderData && (orderData.transVid != IOrderTransVid.Car) && (orderData.transVid != IOrderTransVid.Public);
    const divTrans = company && company.transKind == ICompanyTransKind.PublicAndCar && <div className='reserve-section'>
        <div className='reserve-section-title'>Трансфер</div>
        <Radio.Group onChange={(e) => onValuesChange({transVid: e.target.value})} value={orderData.transVid}>
            <Space direction="vertical">
                <Radio value={1}>Общественный транспорт</Radio>
                <Radio value={2}>Собственный автомобиль. Гос.номер {orderData.transVid === 2 ? 
                    <Input style={{ width: 100, marginLeft: 10 }} placeholder='X000XX' onChange={(e) => onValuesChange({transName: e.target.value})}/> : null}
                </Radio>
            </Space>
        </Radio.Group>
        {needTrans() && (<div className='reserve-agreed'>Для продолжения бронирования необходимо выбрать вариант трансфера</div>)}
    </div>
    //const disabledTouristData = Boolean(user);// Boolean(orderData.touristId); // Данные туриста заполнены по пользователю
    const fullAgree = agreed && agreed2; // Согласие со всеми условиями
    const readyToBook = orderData.emailChecked && orderData.firstName && orderData.secondName && orderData.phone; // Данные туриста заполнены
    
    // console.log(user);
    // console.log(orderData);

    const onLogout = () => serverFetch('auth/logout', {method: 'POST'})
        .then(() => d(userLoaded(undefined)))
        .catch(() => d(userLoaded(undefined)));

    return (<>
        {!isModuleMode && <MainHeader />}
        <div className='search-companies-container'>
            {isModuleMode && <div className='search-buy-put'>КУПИТЬ ПУТЁВКУ</div>}
            <SearchRoomsFilter
                onSearch={(filter: IReservationRoomFilter) => {}}
                loading={false}
                searched={true}
                collapsed={true}
                setFilterCollapsed={goCompany}
                isReady={true}
                clearResults = {null}
                onSelectCategory={null}
            />
            {loading ? <Spinner /> : currentVar ? <div className='reserve'>
                <div className='reserve-section'>{isMobile ? divCurrent : <div className='reserve-column2'>{divCurrent}</div>}</div>
                {nextVar && nextCat && !booking && <div className='reserve-section'>{isMobile && <>{divNext}</>}{!isMobile && <div className='reserve-column2'>{divNext}</div>}</div>}
                {currentVar && <BookingOrderFloat currentTariff={currentVar} orderData={orderData} optionalProffitSerives={optionalProffitSerives} selectedPit={selectedPit}
                    setSelectedPit={setSelectedPit} optionalServices={optionalServices} company={company} booking={booking} setOrderData={setOrderData} />}
                {divTrans}
                {!needTrans() && <>
                    {/* Условия и согласия */}
                    <div className='reserve-section'>
                        <div className='reserve-section-title'>Правила аннуляции заявки</div>
                        <div>{company?.cancelOrderRuleForTourist}</div>
                    </div>
                    <div className='reserve-section'>
                        <div className='reserve-section-title'>Условия бронирования</div>
                        <div className='reserve-info'>{company?.bookingExtraInformationForTourist}</div>
                        <div className='reserve-links'>
                            {process.env.REACT_APP_DOC_USL && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_USL)}`} target='_blank'>Условия и правила</Link>}
                            {process.env.REACT_APP_DOC_AGREEMENT && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_AGREEMENT)}`} target='_blank'>Пользовательское соглашение</Link>}
                            {process.env.REACT_APP_DOC_ZAYAVL && <Link to={`/article/${encodeURIComponent(process.env.REACT_APP_DOC_ZAYAVL)}`} target='_blank'>Заявление на аннуляцию оплаченной путевки</Link>}
                        </div>
                        {fullAgree || <div className='reserve-agreed'>Для продолжения бронирования необходимо согласиться с условиями</div>}
                        <Checkbox className='reserve-agreed' checked={agreed} onChange={(e: CheckboxChangeEvent) => setAgreed(e.target.checked)} disabled={booking}>Подтверждаю согласие с Правилами аннуляции заявки и Условиями бронирования</Checkbox>
                        <Checkbox className='reserve-agreed' checked={agreed2} onChange={(e: CheckboxChangeEvent) => setAgreed2(e.target.checked)} disabled={booking}>Подтверждаю согласие на обработку персональных данных</Checkbox>
                    </div>                
                    {/* Форма ввода данных пользователя */}
                    {fullAgree && <div className='reserve-section'>
                        <div className='reserve-section-title'>Оформление заявки</div>
                            <Spin spinning={booking} tip='Подтверждение брони...'>
                                <Form
                                    size={'large'}
                                    form={form}
                                    initialValues={{ email: '', code: '', codeSent: false, emailChecked: false, firstName: '', secondName: '', lastName: '', phone: '',}}
                                    labelCol={{ span: isMobile ? 6 : 4 }}
                                    wrapperCol={{ span: isMobile ? 18 : 6 }}
                                    layout='horizontal'
                                    colon={false}
                                    onValuesChange={onValuesChange}
                                >
                                    <Form.Item label='Ваша почта' name='email'><Input placeholder='Введите адрес электронной почты' disabled={orderData.emailChecked} /></Form.Item>
                                    {orderData.emailChecked && <Form.Item label=' '><Button onClick={onLogout}>Очистить</Button></Form.Item>}
                                    {!orderData.emailChecked && <Form.Item wrapperCol={{ offset: 4 }}>
                                        <Button className='action-button' onClick={onSendCode} disabled={orderData.emailChecked}>ОТПРАВИТЬ КОД НА ПОЧТУ</Button>
                                        {orderData.codeSent && <div>Код отправлен на почту</div>}
                                        <div style={{fontSize: 12, color: 'gray', marginTop: 4}}>Нажимая кнопку Отправить код на почту и Забронировать, Вы соглашаетесь 
                                        c <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2169")}`} target="_blank"><strong>Условиями и правилами</strong></Link> и <Link to={`/article/${encodeURIComponent("https://kb.rr-tour.ru/article/2170")}`} target="_blank"><strong>Политикой конфиденциальности</strong></Link>.
                                        </div>
                                    </Form.Item>}
                                    {orderData.codeSent && <>
                                            <Form.Item label='Введите код' name='code'><Input disabled={orderData.emailChecked} placeholder='Введите код из почты' /></Form.Item>
                                            <Form.Item wrapperCol={{ offset: 4 }}><Button className='action-button' disabled={!orderData.codeSent || orderData.emailChecked} onClick={onCheckCode}>ПРОВЕРИТЬ КОД</Button></Form.Item>
                                    </>}
                                    {orderData.emailChecked && <>
                                            <Form.Item wrapperCol={{ offset: 4 }}><div>Ваши данные</div></Form.Item>
                                            <Form.Item label='Фамилия' name='secondName'><Input disabled={user && user.touristSurname} /></Form.Item>
                                            <Form.Item label='Имя' name='firstName'><Input disabled={user && user.touristName} /></Form.Item>
                                            <Form.Item label='Отчество' name='lastName'><Input disabled={user && user.touristPatronymic} /></Form.Item>
                                            <Form.Item label='Телефон' name='phone'><MaskedInput mask='+{7}0000000000' disabled={user && user.touristPhone} /></Form.Item>
                                    </>}
                                    <Form.Item wrapperCol={{ offset: 4 }}> {currentVar && <div>Общая стоимость {currentVar.amountWithDiscount.toLocaleString('ru-RU')} ₽</div>}</Form.Item>
                                    <Form.Item wrapperCol={{ offset: 4 }}>
                                        <Button className='action-button' disabled={!readyToBook} onClick={onBook} id='booking-order-btn-book'>ЗАБРОНИРОВАТЬ</Button>
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </div>}
                    </>
                }
            </div> : <div className='reserve-section'><div className='reserve-section-title'>Нет вариантов для бронирования</div></div>}
        </div>
        <MainFooter hidden={isModuleMode}/>
    </>);
};

export default BookingOrder;