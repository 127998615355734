import React from "react";
import {Alert, Button} from 'antd'
import './category-var-item.css';
import { IOptionalService } from "@src/store/entities/optional-service";
import AleanPlaces from "./alean-places";

// Вариант бронирования с выбором тарифа (после выбора категории и дня)
const CategoryVarItem = ({...props}) => {
    const {item, tariff, goBook, isBest, isDiscount, filter} = props;
    const divIncluded = tariff && tariff.optionalServices.map((x:IOptionalService) => <li key={x.optionalServiceId}>{x.name}</li>);
    const needChangeTime = item.orderTimeId && item.orderTimeId != filter.orderTimeId;
    const divDiscountPrice = item.amount != item.amountWithDiscount && <div className="category-vars-category-bottom-red">{item.amount}</div>
    const divButton = needChangeTime ? <Alert message="Измените время заселения для применения тарифа" type="error" showIcon />
       : <Button className="category-var-item-btn" onClick={goBook}>ЗАБРОНИРОВАТЬ</Button>
    //console.log(item);
    const divAleanPlaces = item.aleanRoomPlacing 
        && item.aleanTouristTypes 
        && <AleanPlaces aleanRoomPlacing={item.aleanRoomPlacing} aleanTouristTypes={item.aleanTouristTypes} />
    
    return <div className="category-var-item-container">
        <div className="category-var-item-col1">
            <div className="category-var-item-name">{item.tariffName}</div>
            
            {tariff && tariff.optionalServices.length > 0 && <div className="category-var-item-included">В стоимость включено:</div>}
            <div className="category-var-item-included-text">{divIncluded}</div>
            {divAleanPlaces}
        </div>
        <div className="category-var-item-col2">
            {!needChangeTime && <div className="category-var-item-price">{item.amountWithDiscount.toLocaleString('ru-RU')} ₽{divDiscountPrice}</div>}
            {divButton}
        </div>
        
        {!needChangeTime && <div className="category-var-item-images">
            {isBest && <img src="/images/best_tariff_116.png"/>}
            {(item.amount != item.amountWithDiscount || isDiscount) && <img src="/images/online_skidka_red_m.png"/>}
        </div>}
    </div>
}

export default CategoryVarItem;