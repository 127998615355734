import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import SearchRoomsFilter from '@src/components/search-categories/search-companies-filter';
import CategoryVars from '@components/search-categories/category-vars';
import CategoryVarDays from './category-var-days';
import { IReservationRoomFilter, IInfrastructure, IRoomCategory, ISearchAvailRoom, IRoomPricePeriod, ICompany, IImage } from '@entities/index';
import { serverFetch } from '@src/server';
import './search-categories.css';
import { Dayjs } from 'dayjs';
import { setStyleProperty, strToInt } from '@src/extensions/utils';
import { useAppDispatch } from '@store/hooks';
import { categoryFilterChanged } from '@src/store/actions';
import { MainHeader, MainFooter } from '../main/index';
import LocalFuncs from '@src/extensions/local-funcs';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

interface ISelectedTarif {
    tariffId: number;
    pricePeriodId: number;
    arrivalDate: Dayjs;
    departureDate: Dayjs;
    tariffExtraCode?: string;
}
// Главное окно поиска номеров
const SearchCategories = () => {
    const windowWidth = useWindowWidth();
    const { companyId } = useParams();
    const location = useLocation();
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [roomCategories, setRoomCategories] = useState<Array<IRoomCategory>>([]);
    const [roomPricePeriod, setRoomPricePeriod] = useState<Array<IRoomPricePeriod>>([]);
    const [qtySeats, setQtySeats] = useState<number>(1);
    const [images, setImages] = useState<Array<IImage>>([]);
    const [infras, setInfras] = useState<Array<IInfrastructure>>([]);
    const [filterDefault, setFilterDefault] = useState<IReservationRoomFilter>();
    const [filterCollapsed, setFilterCollapsed] = useState<boolean>(false);
    const [selectedTarif, setSelectedTarif] = useState<ISelectedTarif>();
    const [isModuleMode] = useState<boolean>(location.pathname.startsWith('/module') || window.self !== window.top);
    const [company, setCompany] = useState<ICompany>();
    const [loaded1, setLoaded1] = useState<boolean>(false);
    const [loaded2, setLoaded2] = useState<boolean>(false);
    const [loaded3, setLoaded3] = useState<boolean>(false);
    const [loaded4, setLoaded4] = useState<boolean>(false);
    const d = useAppDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const roomCategoryId = urlParams.get('roomCategoryId');
    const oldFilter = useSelector((s:any) => s.reservationRoomFilter);

    const isMobile = windowWidth <= 720;
    const isTablet = windowWidth > 720 && windowWidth <= 1130;
    const isDesktop = windowWidth > 1130;

    useEffect(() => {
        serverFetch(`companies/${companyId}`, { method: 'GET' })
        .then((data: ICompany) => {
            document.title = data.metaTitle ?? 'Бронирование онлайн. РусРегионТур';
            setCompany(data);
            if (isModuleMode) {
            setStyleProperty('--filter-color', data.filterColor);
            setStyleProperty('--button-color', data.buttonColor);
            }
        })
        .catch(e => console.log(`Ошибка получения компании ${e.userMessage || ''} `));
    }, [companyId]);

    useEffect(() => {        
        getCategories();
        getTariffs();
        getImages();
        getInfra();        
    }, []);

    useEffect(() => {!isModuleMode && onSearch(LocalFuncs.checkFilterPeriod(oldFilter))}, [roomCategories]);

    const [searched, setSearched] = useState<boolean>(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(strToInt(roomCategoryId));
    const [items, setItems] = useState<Array<ISearchAvailRoom>>([]);
    const roomCategoryUrlParam = roomCategoryId && roomCategoryId != '0' ? `/${roomCategoryId}` : '';

    const getCategories = () => {
        serverFetch(`booking/GetCategories/${companyId}${roomCategoryUrlParam}`, { method: 'GET' })
            .then((data) => {
                setRoomCategories(data);
                setLoaded1(true);
            })
            .catch((e) => console.log(`Ошибка получения категорий. ${e.userMessage || ''} `));
    };

    const getImages = () => {
        serverFetch(`booking/GetCategoriesGallery/${companyId}${roomCategoryUrlParam}`, { method: 'GET' })
            .then((data) => {
                setImages(data);
                setLoaded3(true);
            })
            .catch((e) => console.log(`Ошибка получения фото. ${e.userMessage || ''} `));
    };

    const getTariffs = () => {
        serverFetch(`booking/GetTariffs/${companyId}`, { method: 'GET' })
            .then((data) => {
                setRoomPricePeriod(data);
                setLoaded2(true);
            })
            .catch((e) => console.log(`Ошибка получения тарифов ${e.userMessage || ''} `));
    };

    const getInfra = () => {
        serverFetch(`booking/GetCategoriesInfrastructure/${companyId}`, { method: 'GET' })
            .then((data) => {
                setInfras(data);
                setLoaded4(true);
            })
            .catch((e) => console.log(`Ошибка получения фото ${e.userMessage || ''} `));
    };

    const onSearch = (filter: IReservationRoomFilter) => {
        setTableLoading(true);
        setSelectedCategoryId(0);

        const calcAsk = {
            companyId,
            adults: filter.adultsQty,
            arrival: filter.arrivalDate.format("YYYY-MM-DD"),
            departure: filter.departureDate.format("YYYY-MM-DD"),
            roomCategoryId: filter.roomCategoryId,
            tariffId: filter.selectedTariff == 0 ? [] : [filter.selectedTariff],
            tarifs: [],
            userType: 0,
            childrenAges: filter.childAges?.slice(0, filter.childsQty),
            promocode: filter.promoCode,
            orderTimeId: filter.orderTimeId,
            
        };

        serverFetch(`calc/GetAvailableRooms`, { method: 'POST', bodyData: calcAsk, })
            .then((data) => {
                setQtySeats(data.qtySeats);
                const newItems = data.rooms.map((elem: IReservationRoomFilter, id: number) => {
                    const elem2 = { ...elem, id };
                    return elem2;
                });
                const newRoomCategories = [...roomCategories];
                newRoomCategories.forEach((x) => {
                    x.amount = 0;
                    x.amountWithDiscount = 0;
                    x.qtyRest = 0;
                });
                newItems.forEach((x: ISearchAvailRoom) => {
                    const category = newRoomCategories.find((c) => x.roomCategoryExtraId ? c.roomCategoryExtraId == x.roomCategoryExtraId : c.roomCategoryId == x.roomCategoryId);
                    if (category && (category.amountWithDiscount == 0 || category.amountWithDiscount > x.amountWithDiscount)) {
                        category.amount = x.amount;
                        category.amountWithDiscount = x.amountWithDiscount;
                        category.qtyRest = x.qtyRest;
                    }
                });
                setItems(newItems);
                setSearched(true);
                setTableLoading(false);
                setFilterDefault(filter);
            })
            .catch((e) => {
                setTableLoading(false);
                console.log(`Ошибка получения вариантов размещения. ${e.userMessage || ''} `);
            });
    };
    const onSelectCategory = async (id: number) => setSelectedCategoryId(id)
    const onScroll = () => {
        const element = document.getElementById('category-var-days-container');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const selectedCategory = roomCategories.find((cat) => cat.roomCategoryId == selectedCategoryId);
    // Поиск самого дешевого тарифа для флага "Лучшая цена"
    const bestItem = !items || items.length == 0 ? null : items.sort((a, b) => a.amountWithDiscount - b.amountWithDiscount)[0];
    const getImagesForCat = (categoryId?: number, categoryExtraId?: string) => {
        if (categoryExtraId) return images.filter((i) => i.objectExtraId == categoryExtraId);
        if (categoryId && categoryId != 0) return images.filter((i) => i.objectId == categoryId);
        return [];
    };
    const getInfrasForCat = (categoryId?: number) => categoryId ? infras.filter((i: IInfrastructure) => i.objectId == categoryId) : [];
    // Отфильтровать категории по количеству мест
    // Отсортировать в порядке сначала по возрастанию цены от меньшей к большей, потом остальные без цен
    // Разделить на 2 массива чтобы детализация цен по выбранной категории была после первого массива
    const sortedCat:IRoomCategory[] = [];
    const cat1 = roomCategories.filter((item: IRoomCategory) => ((item.countMainBeds + item.countExtraBeds) >= qtySeats)).sort((a,b) => a.amountWithDiscount - b.amountWithDiscount);
    cat1.filter(i => i.amountWithDiscount > 0).forEach(item => sortedCat.push(item));
    cat1.filter(i => i.amountWithDiscount == 0).forEach(item => sortedCat.push(item));
    const selectedIdx = sortedCat.findIndex((a) => a.roomCategoryId == selectedCategoryId);
    let sortedCatA: IRoomCategory[] = [];
    let sortedCatB: IRoomCategory[] = [];    
    if (selectedIdx == -1 || sortedCat.length <= 3) sortedCatA = sortedCat;
    else {
        const nMaxCol = isMobile ? 1 :  isTablet ? 2 : 3;
        const nLine = Math.ceil((selectedIdx + 1) / nMaxCol) - 1;
        sortedCat.forEach((cat, idx) => {
            const nLineC = Math.ceil((idx + 1) / nMaxCol) - 1;
            if (nLineC <= nLine) sortedCatA.push(cat);
            else sortedCatB.push(cat);
        });
    }
    const divCatA =
        searched &&
        sortedCatA.map((cat) => {
            return (
                <CategoryVars
                    id={`cat${cat.roomCategoryId}`}
                    key={`cata-${cat.roomCategoryId}`}
                    category={cat}
                    items={items.filter((e) => cat.roomCategoryExtraId ? e.roomCategoryExtraId == cat.roomCategoryExtraId : e.roomCategoryId == cat.roomCategoryId)}
                    bestPrice={cat.roomCategoryExtraId ? bestItem?.roomCategoryId == cat.roomCategoryId : bestItem?.roomCategoryExtraId == cat.roomCategoryExtraId}
                    selected={selectedCategoryId == cat.roomCategoryId}
                    onSelectCategory={onSelectCategory}
                    images={getImagesForCat(cat.roomCategoryId, cat.roomCategoryExtraId)}
                    infras={getInfrasForCat(cat.roomCategoryId)}
                    hasChoice={items.filter((item: ISearchAvailRoom) => cat.roomCategoryExtraId ? item.roomCategoryExtraId == cat.roomCategoryExtraId : item.roomCategoryId == cat.roomCategoryId).length > 1}
                />
            );
        });
    const divCatB =
        searched &&
        sortedCatB.map((cat) => {
            return (
                <CategoryVars
                    id={`cat${cat.roomCategoryId}`}
                    key={`catb-${cat.roomCategoryId}`}
                    category={cat}
                    items={items.filter((e) => cat.roomCategoryExtraId ? e.roomCategoryExtraId == cat.roomCategoryExtraId : e.roomCategoryId == cat.roomCategoryId)}
                    bestPrice={cat.roomCategoryExtraId ? bestItem?.roomCategoryId == cat.roomCategoryId : bestItem?.roomCategoryExtraId == cat.roomCategoryExtraId}
                    selected={selectedCategoryId == cat.roomCategoryId}
                    onSelectCategory={onSelectCategory}
                    images={getImagesForCat(cat.roomCategoryId, cat.roomCategoryExtraId)}
                    infras={getInfrasForCat(cat.roomCategoryId)}
                    hasChoice={false}
                />
            );
        });
    const divResultsA = searched && <div className='search-categories-vars-container' key={'search-categories-vars-container-1'}>{divCatA}</div>
    const divResultsB = searched && <div className='search-categories-vars-container' key={'search-categories-vars-container-2'}>{divCatB}</div>
    const onSelectVariant = (tariffId: number, pricePeriodId: number, arrivalDate: Dayjs, departureDate: Dayjs, tariffExtraCode?: string) => {
        setSelectedTarif({ tariffId, pricePeriodId, arrivalDate, departureDate, tariffExtraCode });
        setFilterCollapsed(true);
        const newFilter = {...filterDefault, arrivalDate, departureDate};
        d(categoryFilterChanged(newFilter));
    };
    let childAges: Number[] = [];
    if (filterDefault && filterDefault) {
        childAges = Array.from(filterDefault.childAges);
        childAges.length = Math.min(filterDefault.childsQty, childAges.length);
    }
    //console.log(company);
    const divMeta = company && <Helmet>
        <meta charSet='urf-8' />
        {company.metaDescription && <meta name='description' content={company.metaDescription} />}
        {company.metaKeywords && <meta name='keywords' content={company.metaKeywords} />}
    </Helmet>
    return <>
        {divMeta}
        {!isModuleMode && <MainHeader />}
        <div className='search-companies-container'>
            {isModuleMode && <div className='search-buy-put'>КУПИТЬ ПУТЁВКУ</div>}
            <SearchRoomsFilter
                onSearch={(filter: IReservationRoomFilter) => onSearch(filter)}
                loading={tableLoading}
                searched={searched}
                collapsed={filterCollapsed}
                setFilterCollapsed={setFilterCollapsed}
                isReady={loaded1 && loaded2 && loaded3 && loaded4}
                clearResults = {() => setSearched(false)}
                onSelectCategory={onSelectCategory}
            />
            {!isModuleMode && company && <div className='search-companies-company-description'>{company.description}</div>}
            {divResultsA}
            {searched && selectedCategory && filterDefault && (
                <CategoryVarDays
                    category={selectedCategory}
                    filterDefault={filterDefault}
                    key={selectedCategoryId}
                    roomPricePeriods={roomPricePeriod}
                    onScroll={onScroll}
                    onSelect={onSelectVariant}
                />
            )}
            {divResultsB}
        </div>
        <MainFooter hidden={isModuleMode}/>
    </>
};

export default SearchCategories;