import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MainHeader from "../main/main-header";
import MainFooter from "../main/main-footer";

const Article = () => {
    const { url } = useParams();
    const [h, setH] = useState(200);

    window.addEventListener('message', (event) => {
        const data = event.data;
        if (data["sender"] == "proffit") setH(data["height"]);
    });

    return<>
        <MainHeader />
        <object data={url} style={{width: "100%", minHeight: h}}/>
        <MainFooter hidden={false}/>
    </>
}

export default Article;