import React, {useState, useEffect} from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import { serverFetch } from '@src/server';
import { exception } from '@extensions/notification';
import {notification, Spin, Carousel, Tooltip, Input, Button, Skeleton, message } from 'antd';
import {fmtDateFull, orderStatusToStr, fmtTimeShort, fmtDateShort, fmtDateTimeFull, weekDayToStr } from "@extensions/utils";
import './booking.css';
import { IOrder, IRoomCategory, IInfrastructure, IRoomPricePeriod, IOptionalService, ICompany } from "@entities/index";
import { useWindowWidth } from '@react-hook/window-size';
import { IOrderStatus } from "@enums/index";
import Raif from "../payment-providers/raif";
import { Dayjs } from "dayjs";
import { Spinner } from "@src/controls";
import { IOrderTransVid } from "@src/store/enums/order-trans-vid";
import { MainHeader, MainFooter } from "../main/index";
import Transaction from "../payment-providers/transaction";
const { TextArea } = Input;
const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Просмотр заявки
const Booking = () => {
    const location = useLocation();
    const { id } = useParams();
    const [order, setOrder] = useState<IOrder>();
    const [category, setCategory] = useState<IRoomCategory>();
    const [company, setCompany] = useState<ICompany>();
    const [price, setPrice] = useState<IRoomPricePeriod>();
    const [api, _] = notification.useNotification();
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile:boolean = useWindowWidth() <= 992;
    const [startWaiting] = useState<Dayjs>(dayjs())
    const [waitingSecs, setWaitingSecs] = useState<number>(0);
    const [timeIsOut, setTimeIsOut] = useState<boolean>(false);
    const [isModuleMode] = useState<boolean>(location.pathname.startsWith('/module') || window.self !== window.top);
    const [transactions, setTransactions] = useState<any>([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const badpayParam = urlParams.get('payerror');

        if (badpayParam === 'payerror') {
            message.error('Ошибка оплаты');
            // Удаляем параметр "badpay" из URL без перезагрузки страницы и истории браузера
            const newUrl = window.location.pathname + window.location.search.replace(/(\?|&)payerror=payerror/g, '');
            window.history.replaceState(null, '', newUrl);
        }
    })

    const CheckOrderStatus = (order:IOrder) => {
        const id = order.orderId;
        serverFetch(`Booking/GetOrderStatus/${id}`, {method: 'GET'})
            .then((data) => {
                if (dayjs(order?.paymentDeadline + '+03:00') < dayjs() && !timeIsOut)
                    setTimeIsOut(true);
                if (data.orderStatus != order.orderStatus || order.price != data.price || order.payment != data.payment)
                {
                    window.location.reload();
                }
                else {
                    if (order?.orderStatus == IOrderStatus.New){
                        var waitingSecsNew = dayjs().diff(startWaiting, 'second');
                        //console.log(startWaiting, dayjs(), waitingSecsNew);
                        if (waitingSecsNew > waitingSecs){
                            setWaitingSecs(waitingSecsNew);
                        }
                    }
                    setTimeout(() => {CheckOrderStatus(order)}, 1000);
                }
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка статуса заказа', ex);
            });
    }

    useEffect(() => {
        serverFetch(`payment/transactions/${id}`, {method: 'GET'})
            .then((data:any) => {
                console.log(data);
                setTransactions(data);
            })
            .catch((ex) => console.log(`Ошибка получения транзакций ${ex.userMessage}`))
    }, [])

    useEffect(() => {
        serverFetch(`booking/${id}`, {method: 'GET'})
            .then((data) => {
                console.log(data);
                data && data.order && (data.order.orderStatus == IOrderStatus.New || data.order.orderStatus == IOrderStatus.Confirmed) && CheckOrderStatus(data.order);
                setOrder(data.order);
                setCategory(data.category);
                setPrice(data.price);
                setLoading(false);
                serverFetch(`companies/${data.order.companyId}`, {method: 'GET'})
                    .then((data) => setCompany(data))
                    .catch((ex) => {                        
                        setLoading(false);
                        exception(api, 'Ошибка получения компании', ex);
                    });
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения заявки', ex);
            });
    }, [id]);    
    const contentStyle: React.CSSProperties = {
        margin: 0,
        width: 350,
        height: 230,
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const divIncluded = price && price.optionalServices && price.optionalServices.map((x:IOptionalService) => <div className="booking-order-float-item" key={x.baseOptionalServiceId}>{x.name}</div>);

    const arrival = dayjs(order?.arrivalDate);
    const departure = dayjs(order?.departureDate);

    const onPayCard = () => { 
        setLoading(true);
        serverFetch(`payment/ByTinkoff/${order?.orderId ?? 0}`, { method: 'GET' })
            .then((url) => window.location.href = url)
            .catch(data => {
                setLoading(false);
                message.error(`Ошибка ${data}`);
            });
    }

    const onPayCredit = () => {
        setLoading(true);
        serverFetch(`payment/ByTinkoffCredit/${order?.orderId ?? 0}`, { method: 'GET' })
            .then((url) => window.location.href = url)
            .catch(data => {
                setLoading(false);
                message.error(`Ошибка ${data}`);
            });
    }
    // const onPayCard = () => {
    //     serverFetch(`payment/BySbrf/${id}`, {method: 'GET'})
    //         .then(data => window.location.href = data.url)
    //         .catch((ex) => {
    //             setLoading(false);
    //             exception(api, 'Ошибка оплаты заявки', ex);
    //         });
    // }

    const onPayQR = () => {
        if (order){
            Raif(order.price - order.payment, 
                `${order.orderId}-${order.paymentCount ?? 0}`,
                //`${order.orderNumber}_${order.companyId}`,
                `Оплата путевки ${order.orderNumber}/${order.orderId}/${order.companyId}`,
                window.location.href
                );
        }
    }   

    const addDay = dayjs(order?.arrivalTime).get("hour") < dayjs(order?.departureTime).get("hour") ? 1 : 0;
    const divOptionalServices = order && order.vidPitName && <div>
        <div className="booking-order-float-item booking-order-float-caption">Питание</div>
        <div className="booking-order-float-item">{order.vidPitName}</div>
    </div>
    const divTrans = order && order.transVid == IOrderTransVid.Car && <div>Автомобиль {order.transName}</div>
    const divFloat = <div className="booking-float">
        <div className="reserve-section-title">Заявка # {order && order.orderId}</div>
        <div className="booking-order-float-item booking-order-float-caption">Размещение</div>
        <div className="booking-order-float-item">
            <div className="booking-order-float-label">Заезд</div>
            <div>
                {weekDayToStr(dayjs(arrival).day())} {fmtDateFull(dayjs(arrival))} {order && order.arrivalTime && fmtTimeShort(dayjs(order.arrivalTime))}
            </div>
        </div>
        <div className="booking-order-float-item">
            <div className="booking-order-float-label">Выезд</div>
            <div>
                {weekDayToStr(dayjs(departure).day())} {fmtDateFull(dayjs(departure))} {order && order.departureTime && fmtTimeShort(dayjs(order.departureTime))}
            </div>
        </div>
        <div className="booking-order-float-item">
            <div className="booking-order-float-label">Срок</div>
            <div>
                {dayjs(departure).diff(dayjs(arrival), 'day', true) + addDay} суток
            </div>
        </div>
        <div className="booking-order-float-item booking-order-float-caption">Гости</div>
        <div className="booking-order-float-item">
            {order?.countAdults} взрослых {order && order.countChilds > 0 && `+ ${order.countChilds} детей`}
        </div>
        <div className="booking-order-float-item booking-order-float-caption">Тариф</div>
        <div className="booking-order-float-item">{price && price.name}</div>
        <div className="booking-order-float-item">
            <div className="booking-order-float-label">Включено</div>
        </div>
        {divIncluded}
        {divOptionalServices}
        <div className="booking-order-float-item booking-order-float-caption">Пожелания</div>
        <TextArea 
            autoSize={{ minRows: 4}} 
            disabled={true} 
            value={order?.additionalComments} 
            style={{marginTop: 4}}
        />
        {divTrans}
        {order && <div className="booking-tariff-price">Итого {order.price.toLocaleString('ru-RU')} ₽</div>}
    </div>

    const divWait = !company?.proffitSystem && !company?.aleanSystem ? <div>Ожидаем подтверждения оператором</div> : <div className="booking-spin">
        <Spinner  />
        <div>{`Ожидание подтверждения... ${waitingSecs < 60 ? "" : "Сервер объекта размещения недоступен. После подтверждения бронирования письмо придёт на указанную вами почту и вы сможете продолжить бронирование"}`}</div>
    </div>

    const divSpin = order?.orderStatus == IOrderStatus.New && divWait
    const urlNewOrder = isModuleMode ? `/module/booking/search/category/${company?.companyId}` : `/booking/search/category/${company?.companyId}`
    
    const divTransactions = transactions.map((transaction:any) => <Transaction transaction={transaction} />)

    const onVaucher = () => {
        const imageUrl = `${process.env.REACT_APP_API_BASE_URL}/api/booking/vaucher/${id}`;
        // Создание ссылки для загрузки файла
        const downloadLink = document.createElement('a');
        downloadLink.href = imageUrl;
        downloadLink.setAttribute('download', 'image.jpg'); // Указание имени файла для загрузки
        // Эмуляция клика для загрузки файла
        downloadLink.click();
    }
    const needPay = (order?.price ?? 0) - (order?.payment ?? 0);
return <>
    {!isModuleMode && <MainHeader />}
    <div className="booking-container">   
        {company ? <div className='search-companies-filter-company'>{company && company.companyName}</div>: <Skeleton.Input />}
        <Spin spinning={loading} tip="Загрузка..."/>
        <div className="booking-row">
            <div className="booking-col1">
                <div style={{ width: 350 }} className="booking-gallery">
                    {category && category.gallery && category.gallery.length > 0 ?
                        <Carousel>
                            {category.gallery.map((i) => {
                                return (
                                        <div key={i.imageId}>
                                            <img style={contentStyle} src={i.source ? i.source : `${process.env.REACT_APP_API_BASE_URL}/api/image${i.imageFileName}`} />
                                        </div>
                                );
                            })}
                        </Carousel> : <Skeleton.Image active/>
                    }
                </div>
                <div className="booking-title">{category?.name}</div>
                <div className="booking-description">{category?.description}</div>
                <div className="booking-infra">
                    {category && category.infrastructure && category.infrastructure.map(
                        (e : IInfrastructure) => <Tooltip title={e.name} key={e.infrastructureID}>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/api/image${e.imageFileName}`} key={e.infrastructureID}/>
                        </Tooltip>
                    )}
                </div>
                {isMobile && divFloat}
                <div className="booking-title">Статус: {orderStatusToStr(order?.orderStatus)}</div>
                {order?.orderStatus == IOrderStatus.New && order.confirmedDeadline && <p>Ожидание подтверждения заявки до {fmtDateTimeFull(dayjs(order.confirmedDeadline))} мск</p>}
                {order?.orderStatus == IOrderStatus.Confirmed && <>
                    <p>Ваша заявка № {order.orderNumber}/{order.proffitOrderId} на период с {fmtDateShort(dayjs(order.arrivalDate))} по {fmtDateShort(dayjs(order.departureDate))} в 
                    размере {order.price.toLocaleString('ru-RU')} руб. успешно подтверждена компанией {company?.companyName}.</p>
                    <p>Вы можете опатить ее сейчас. Также вы можете оплатить ее позже в Личном кабинете портала <a href="https://rr-tour.ru/sales" target="_blank">РусРегионТур</a></p>
                    {order.paymentDeadline && <p><b>Ожидание оплаты заявки - {dayjs(order.paymentDeadline + "+03:00").diff(dayjs(), 'minute')} мин.</b> (до {fmtDateTimeFull(dayjs(order.paymentDeadline))} МСК)</p>}
                    <div className="booking-tariff-price-l">К оплате {(order.price - order.payment).toLocaleString('ru-RU')} ₽ </div>
                    {order.payment > 0 ? <div className="booking-tariff-price-l">(с учетом предоплаты {order.payment})</div> : null}
                    <div className="booking-buttons">
                        <Button className="booking-button" onClick={onPayCard} disabled={timeIsOut}>Оплатить картой<br/>МИР, VISA, MC</Button>
                        <Button className="booking-button" onClick={onPayQR} disabled={timeIsOut}>Оплатить СБП<br/>QR кодом</Button>
                        <Button className="booking-button" disabled={timeIsOut || needPay < 3000} onClick={onPayCredit}>В кредит<br/>{Math.round(needPay/20)} ₽ в месяц</Button>
                        {company && <Link to={urlNewOrder} target="_blank"><Button className="booking-button">Оформить<br/>еще заявку</Button></Link>} 
                    </div>
                </>}
                {divSpin}
                {order?.orderStatus == IOrderStatus.Paid &&<div>
                    <p>Оплата вашей заявки № {order.orderNumber}/{order.proffitOrderId} на период с {fmtDateShort(dayjs(order.arrivalDate))} по {fmtDateShort(dayjs(order.departureDate))} в 
                    размере {order.price.toLocaleString('ru-RU')} руб. успешно зарегистрирована компанией {company?.companyName}.</p>
                    <p>Ваш электронный ваучер отправлен на вашу электронную почту и сохранен в Личном кабинете портала <a href="https://rr-tour.ru/sales" target="_blank">РусРегионТур</a></p>
                    </div>
                }
                {order?.orderStatus == IOrderStatus.Paid && <><div className="booking-title">Оплаты</div>{divTransactions}</>}
                {order?.orderStatus == IOrderStatus.Paid && <Button style={{maxWidth: 300}} onClick={onVaucher}>Скачать ваучер</Button>}
            </div>
            {!isMobile && divFloat}
        </div>
    </div>
    {<MainFooter hidden={isModuleMode}/>}
    </>
}

export default Booking;